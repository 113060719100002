import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
  contentContainer: {
    padding: 24,
    fontSize: 16,
    color: '#000000',
    lineHeight: 24,
    letterSpacing: 0.44,
  },
});

export default styles;
