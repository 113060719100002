import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
  content: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
